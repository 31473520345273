import { useAuth } from "../context/AuthContext";
import { PromptData } from "./types";

const API_PREFIX = process.env.REACT_APP_API_URL;

export const usePrompts = () => {
  const { user } = useAuth();
  const getPrompts = async (): Promise<Array<PromptData>> => {
    if (!user) {
      throw "Unauthenticated";
    }

    const authToken = await user.getIdToken();
    const response = await window.fetch(`${API_PREFIX}/prompt/list_prompts`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    const json = await response.json();

    return json.prompts ?? [];
  };

  return {
    getPrompts,
  };
};
