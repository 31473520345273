import {
  Box,
  Heading,
  Button,
  Text,
  UnorderedList,
  ListItem,
  Flex,
  IconButton,
  useClipboard,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useChatbots } from "./useChatbots";
import { ChatbotData } from "./types";
import { Link } from "react-router-dom";
import {
  CheckCircleIcon,
  CopyIcon,
  DeleteIcon,
  SettingsIcon,
} from "@chakra-ui/icons";

const SHARE_BASE_URL =
  process.env.REACT_APP_APP_URL ?? "https://chatultra.vectara.dev/";

export const Chatbots = () => {
  const { getChatbots } = useChatbots();
  const [chatbotDatas, setChatbotDatas] = useState<Array<ChatbotData>>([]);

  useEffect(() => {
    const doAsync = async () => {
      const result = await getChatbots();
      setChatbotDatas(result);
    };

    doAsync();
  }, []);

  return (
    <Box padding="1rem">
      <Heading size="xl">Chatbots</Heading>
      <Link to="/create-chatbot">
        <Button colorScheme="blue" aria-label="Create a Chatbot" marginTop={4}>
          <Text size="m">Create a new chatbot</Text>
        </Button>
      </Link>
      <Box marginTop={6}>
        <UnorderedList
          listStyleType="none"
          display="flex"
          gap={4}
          margin={0}
          flexWrap="wrap"
        >
          {chatbotDatas.map((chatbotData) => (
            <ChatbotItem
              key={chatbotData.id}
              id={chatbotData.id}
              corpusId={chatbotData.corpus_id}
              name={chatbotData.name}
            />
          ))}
        </UnorderedList>
      </Box>
    </Box>
  );
};

const ChatbotItem = ({
  id,
  corpusId,
  name,
}: {
  id: string;
  corpusId: string;
  name: string;
}) => {
  const [shareLinkCopied, setShareLinkCopied] = useState<boolean>(false);

  const { onCopy } = useClipboard(`${SHARE_BASE_URL}/share/${id}`);

  return (
    <ListItem>
      <Flex direction="column" border="1px solid #cdcdcd" borderRadius="8px">
        <Box padding=".5rem 1rem">
          <Text size="s" fontWeight={600}>
            {name}
          </Text>
        </Box>
        <Box padding={4} borderTopWidth="1px" borderTopColor="gray.300">
          <Text fontSize=".9rem" color="gray.600" fontWeight={600}>
            Sample chatbot description
          </Text>
        </Box>
        <Flex
          borderTopWidth="1px"
          borderTopColor="gray.300"
          justifyContent="flex-end"
          padding={1}
        >
          <Link to={`/chatbot/${id}`} state={{ id, corpusId, name }}>
            <IconButton
              variant="ghost"
              color="#555"
              aria-label="delete"
              icon={<SettingsIcon />}
            />
          </Link>
          {shareLinkCopied ? (
            <Flex
              justifyContent="center"
              alignItems="center"
              paddingLeft={3}
              paddingRight={3}
            >
              <CheckCircleIcon color="green" />
            </Flex>
          ) : (
            <IconButton
              variant="ghost"
              aria-label="share"
              color="#555"
              icon={<CopyIcon />}
              onClick={(e) => {
                e.preventDefault();
                onCopy();
                setShareLinkCopied(true);

                window.setTimeout(() => setShareLinkCopied(false), 2000);
              }}
            />
          )}

          <IconButton
            variant="ghost"
            color="red"
            aria-label="delete"
            icon={<DeleteIcon />}
            onClick={(e) => e.preventDefault()}
          />
        </Flex>
      </Flex>
    </ListItem>
  );
};
