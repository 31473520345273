import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { auth } from "../utils/firebase";
import {
  User,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { setToken, setUserData } from "../utils/userStore";

type SignInResponse = {
  message: string;
  uid: string;
} | null;

interface AuthContextType {
  user: User | null;
  signInWithGoogle: () => Promise<SignInResponse>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("Invalid configuraiton");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const signInWithGoogle = async (): Promise<SignInResponse> => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const tokenRes = await result.user.getIdTokenResult(
        true /* forceRefresh */
      );
      const idToken = tokenRes.token;
      const response = await fetch(`${process.env.REACT_APP_API_URL}/signin`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ idToken }),
      });
      if (response.ok) {
        const data = await response.json();
        setToken(idToken);
        setUserData({
          name: tokenRes.claims?.name,
          email: tokenRes.claims.email ?? "",
          imgUrl: tokenRes.claims.picture,
          user_id: tokenRes.claims.user_id,
        });
        return data;
      }
      throw new Error("Sign-in failed");
    } catch (error) {
      console.error("Error:", error);
      auth.updateCurrentUser(null);
      return null;
    }
  };

  const logout = (): Promise<void> => signOut(auth);

  const value: AuthContextType = { user, signInWithGoogle, logout };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
