import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Button,
  Select,
  Spinner,
} from "@chakra-ui/react";
import { useNavContext } from "../context/NavContext";
import { usePrompts } from "./usePrompts";
import { PromptData } from "./types";
import { useChatbot } from "./useChatbot";
import { useNavigate } from "react-router-dom";

const CreateChatbot: React.FC = () => {
  // Form data
  const [chatbotName, setChatbotName] = useState<string | undefined>(undefined);
  const [chatbotPrompt, setChatbotPrompt] = useState<string | undefined>(
    undefined
  );

  const [chatbotPersona, setChatbotPersona] = useState<string | undefined>(
    undefined
  );

  const { getPrompts } = usePrompts();
  const { createChatbot } = useChatbot();
  const { breadcrumbs, setBreadcrumbs } = useNavContext();
  const [promptDatas, setPromptDatas] = useState<Array<PromptData>>([]);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isPersonasEnabled, setIsPersonasEnabled] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const updatedBreadcrumbs = [
      { name: "Chatbots", link: "/chatbots" },
      { name: "Create Chatbot" },
    ];

    setBreadcrumbs(updatedBreadcrumbs);
  }, []);

  useEffect(() => {
    if (chatbotPrompt === "4") {
      setIsPersonasEnabled(true);
    } else {
      setIsPersonasEnabled(false);
    }
  }, [chatbotPrompt]);

  useEffect(() => {
    const doAsync = async () => {
      const fetchedPromptDatas = await getPrompts();
      setPromptDatas(fetchedPromptDatas);

      if (fetchedPromptDatas.length) {
        setChatbotPrompt(fetchedPromptDatas[0].id);
      }
    };

    doAsync();
  }, []);

  const onCreate = async () => {
    if (!chatbotName || !chatbotPrompt) return;

    setIsCreating(true);
    try {
      const response = await createChatbot(
        chatbotName,
        chatbotPrompt,
        chatbotPersona
      );
      navigate(`/chatbot/${response.id}`);
    } catch {
      setIsCreating(false);
    }
  };

  return (
    <Box
      p="8"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width="100vw"
    >
      <Box
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Heading size="lg" mb="4">
          Create a Chatbot
        </Heading>
      </Box>

      <Box
        p={6}
        borderWidth="1px"
        borderRadius={4}
        borderColor="gray.300"
        width="60%"
        minWidth="320px"
        maxWidth="800px"
      >
        <FormControl>
          <FormLabel>Choose a name</FormLabel>
          <Input
            placeholder="my-chatbot"
            value={chatbotName ?? ""}
            onChange={(e) => setChatbotName(e.target.value)}
            width="100%"
          />
        </FormControl>

        <FormControl marginTop={4}>
          <FormLabel>Choose a prompt</FormLabel>
          <Select
            onChange={(e) => setChatbotPrompt(e.target.value)}
            value={chatbotPrompt}
          >
            {promptDatas.map((promptData) => (
              <option key={promptData.name} value={promptData.id}>
                {promptData.name}
              </option>
            ))}
          </Select>
        </FormControl>

        {isPersonasEnabled ? (
          <FormControl marginTop={4}>
            <FormLabel>Choose a persona</FormLabel>
            <Select
              onChange={(e) => setChatbotPersona(e.target.value)}
              value={chatbotPersona}
            >
              <option key="Aristotle" value="Aristotle">
                Aristotle
              </option>
              <option key="Mickey" value="Mickey Mouse">
                Mickey Mouse
              </option>
              <option key="Mario" value="Super Mario">
                Super Mario
              </option>
              <option key="toddler" value="a toddler">
                a toddler
              </option>
            </Select>
          </FormControl>
        ) : null}

        <FormControl marginTop={4}>
          {isCreating ? (
            <Spinner margin="5px" size="md" color="gray.500" />
          ) : (
            <Button
              colorScheme="blue"
              onClick={onCreate}
              isDisabled={!chatbotName || !chatbotPrompt}
            >
              Create it
            </Button>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};

export default CreateChatbot;
