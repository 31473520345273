import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Fade,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  ListItem,
  Progress,
  Select,
  Slide,
  Spinner,
  Text,
  UnorderedList,
  useClipboard,
  VStack,
} from "@chakra-ui/react";
import { ReactNode, Ref, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavContext } from "../context/NavContext";
import {
  AddIcon,
  CheckCircleIcon,
  CloseIcon,
  CopyIcon,
  DeleteIcon,
  SettingsIcon,
} from "@chakra-ui/icons";
import { useChat } from "./useChat";
import { ChatTurn } from "./types";
import Particles from "@tsparticles/react";
import { useEffectsContext } from "../context/EffectsContext";
import { useChatbotContext } from "../context/ChatbotContext";

import {
  createUseGesture,
  dragAction,
  pinchAction,
  useDrag,
} from "@use-gesture/react";
import { useSpring, animated } from "@react-spring/web";

const SHARE_BASE_URL =
  process.env.REACT_APP_APP_URL ?? "https://chatultra.vectara.dev/";
const useGesture = createUseGesture([dragAction, pinchAction]);

export const Chatbot = () => {
  const { didLoadParticles, particleConfig } = useEffectsContext();
  const { setBreadcrumbs } = useNavContext();
  const params = useParams();
  const { currentChatbot, loadChatbot } = useChatbotContext();

  const id = params.chatbotId;

  useEffect(() => {
    if (!id) {
      return;
    }

    const fetchChatbotData = async () => {
      await loadChatbot(Number(id));
    };

    fetchChatbotData();
  }, [id]);

  useEffect(() => {
    if (currentChatbot) {
      const updatedBreadcrumbs = [
        { name: "Chatbots", link: "/chatbots" },
        { name: currentChatbot.name },
      ];
      setBreadcrumbs(updatedBreadcrumbs);
    }
  }, [currentChatbot]);

  if (!id || !currentChatbot) {
    return null;
  }

  return (
    <Box padding="1rem">
      <Box
        marginTop={2}
        padding="none"
        border="1px solid #cdcdcd"
        borderRadius={4}
        overflow="hidden"
      >
        <Playground
          chatbotId={id}
          effectLayer={
            didLoadParticles && (
              <Particles
                style={{ height: "100%" }}
                id="tsparticles"
                options={{ ...particleConfig, fullScreen: false }}
              />
            )
          }
        />
      </Box>
    </Box>
  );
};

const Playground = ({
  chatbotId,
  effectLayer,
}: {
  chatbotId: string;
  effectLayer?: ReactNode;
}) => {
  const [chatbotPersona, setChatbotPersona] = useState<string | null>(
    window.localStorage.getItem(`chatbot-persona:${chatbotId}`)
  );
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const [allSources, setAllSources] = useState<Array<string> | undefined>(
    undefined
  );
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [links, setLinks] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [ingestionUrls, setIngestionUrls] = useState<Array<string> | undefined>(
    undefined
  );
  const [shouldPromptAddSource, setShouldPromptAddSource] =
    useState<boolean>(false);

  const { currentChatbot, currentChatbotTurns, addSources, listSources } =
    useChatbotContext();

  const particlesWrapperRef = useRef<HTMLDivElement | null>(null);
  const [sourcesUpdated, setSourcesUpdated] = useState<boolean>(false);
  const [copiedShareLinkToClipboard, setCopiedShareLinkToClipboard] =
    useState<boolean>(false);
  const [headerBgColor, setHeaderBgColor] = useState<
    "gray" | "blue" | "purple"
  >("blue");
  const [bgColor, setBgColor] = useState<"gray" | "white">("white");

  const { onCopy } = useClipboard(
    `${SHARE_BASE_URL}/share/${chatbotId}?headerColor=${headerBgColor}&bgColor=${bgColor}`
  );

  const didSave = useRef<boolean>(false);
  const prevAppearanceSettings = useRef<AppearanceConfig>({
    headerBgColor: "blue",
    bgColor: "white",
  });

  useEffect(() => {
    if (allSources && allSources.length === 0) {
      setIsSettingsOpen(true);
      addLink();
      setShouldPromptAddSource(true);
    }
  }, [allSources]);

  useEffect(() => {
    const handler = (e: Event) => e.preventDefault();
    document.addEventListener("gesturestart", handler);
    document.addEventListener("gesturechange", handler);
    document.addEventListener("gestureend", handler);
    return () => {
      document.removeEventListener("gesturestart", handler);
      document.removeEventListener("gesturechange", handler);
      document.removeEventListener("gestureend", handler);
    };
  }, []);

  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    rotateZ: 0,
  }));

  const ref = useRef<HTMLDivElement>(null);
  const dragHandleRef = useRef<HTMLDivElement>(null);

  useDrag(
    ({ pinching, cancel, offset: [x, y], ...rest }) => {
      if (pinching) return cancel();
      api.start({ x, y });
    },
    {
      target: dragHandleRef,
      drag: { from: () => [style.x.get(), style.y.get()] },
    }
  );

  useGesture(
    {
      onPinch: ({
        origin: [ox, oy],
        first,
        movement: [ms],
        offset: [s, a],
        memo,
      }) => {
        if (first) {
          const { width, height, x, y } = ref.current!.getBoundingClientRect();
          const tx = ox - (x + width / 2);
          const ty = oy - (y + height / 2);
          memo = [style.x.get(), style.y.get(), tx, ty];
        }

        const x = memo[0] - (ms - 1) * memo[2];
        const y = memo[1] - (ms - 1) * memo[3];
        api.start({ scale: s, rotateZ: a, x, y });
        return memo;
      },
    },
    {
      target: ref,
      drag: { from: () => [style.x.get(), style.y.get()] },
      pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
    }
  );

  const [isUiDataReady, setIsUiDataReady] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => setIsUiDataReady(true), 500);
  }, []);

  // TODO: Move this to the chatbot context
  const getSources = async () => {
    const sources = await listSources(chatbotId);
    if (!sources) {
      setAllSources([]);
    }
    setAllSources(sources);
  };

  // Reset didSave ref on opening the settings page.
  useEffect(() => {
    if (isSettingsOpen) {
      didSave.current = false;
      prevAppearanceSettings.current = {
        headerBgColor,
        bgColor,
      };
    }
  }, [isSettingsOpen]);

  // If sources are updated, temporarily show the success message.
  useEffect(() => {
    if (!particlesWrapperRef.current) {
      return;
    }

    if (!sourcesUpdated) {
      return;
    }

    const prevClassName = particlesWrapperRef.current.className;
    particlesWrapperRef.current.className = `${prevClassName} synapses--didFire`;

    getSources();

    window.setTimeout(() => {
      if (!particlesWrapperRef.current) return;
      particlesWrapperRef.current.className = prevClassName;
    }, 1000);
  }, [sourcesUpdated]);

  // Whenever chatbot ID changes, get chatbot URL sources.
  useEffect(() => {
    if (!chatbotId) {
      return;
    }

    setAllSources(undefined);

    getSources();
  }, [chatbotId]);

  if (!currentChatbot) {
    return null;
  }

  const onChatResponse = () => {
    if (!particlesWrapperRef.current) {
      return;
    }

    const prevClassName = particlesWrapperRef.current.className;
    particlesWrapperRef.current.className = `${prevClassName} synapses--didFire`;

    window.setTimeout(() => {
      if (!particlesWrapperRef.current) return;
      particlesWrapperRef.current.className = prevClassName;
    }, 750);
  };

  const addLink = () => {
    setLinks([...links, ""]);
  };

  const deleteLink = (index: number) => {
    setLinks(links.filter((_, i) => i !== index));
  };

  const closeSettings = () => {
    setIsSettingsOpen(false);
    if (!didSave.current) {
      setHeaderBgColor(prevAppearanceSettings.current.headerBgColor);
      setBgColor(prevAppearanceSettings.current.bgColor);
    }
  };

  const onSave = async () => {
    setIsSaving(true);
    didSave.current = true;
    closeSettings();
    setLinks([]);

    if (chatbotPersona) {
      window.localStorage.setItem(
        `chatbot-persona:${chatbotId}`,
        chatbotPersona
      );
    }

    if (links.length) {
      await addSources(
        chatbotId,
        links,
        (urlsToIngest) => {
          setIngestionUrls(urlsToIngest);
        },
        () => setIngestionUrls(undefined)
      );

      setSourcesUpdated(true);

      window.setTimeout(() => setSourcesUpdated(false), 5000);
    }

    setIsSaving(false);
  };

  const isPersonasEnabled = !!chatbotPersona;

  return (
    <Box position="relative" ref={wrapperRef}>
      <Flex position="absolute" top={1} right={1} zIndex={100} gap={2}>
        <Fade in={isSaving}>
          {isSaving && !ingestionUrls && (
            <Flex
              height="100%"
              alignItems="center"
              padding={1}
              paddingLeft={2}
              paddingRight={2}
              borderRadius={4}
            >
              <Spinner color="#dedede" />
            </Flex>
          )}

          {isSaving && ingestionUrls && (
            <Flex
              height="100%"
              alignItems="center"
              padding={1}
              paddingLeft={2}
              paddingRight={2}
              borderRadius={4}
            >
              <TimedProgress numUrls={ingestionUrls.length} />
            </Flex>
          )}
        </Fade>
        <Fade in={sourcesUpdated}>
          {sourcesUpdated && (
            <Flex
              height="100%"
              alignItems="center"
              padding={1}
              paddingLeft={2}
              paddingRight={2}
              background="green.500"
              borderRadius={4}
            >
              <Flex gap={2}>
                <CheckCircleIcon color="white" />{" "}
                <Text fontSize=".75em" color="white" fontWeight={600}>
                  Chatbot Knowledge has been updated.
                </Text>
              </Flex>
            </Flex>
          )}
        </Fade>
        <IconButton
          onClick={() => setIsSettingsOpen(true)}
          icon={<SettingsIcon color="#555" />}
          aria-label="Open Chatbot Settings"
        />
      </Flex>
      <Flex position="absolute" top={12} right={1} zIndex={300} gap={2}>
        <Fade in={copiedShareLinkToClipboard}>
          {copiedShareLinkToClipboard && (
            <Flex
              height="100%"
              alignItems="center"
              padding={1}
              paddingLeft={2}
              paddingRight={2}
              background="green.500"
              borderRadius={4}
            >
              <Flex gap={2}>
                <CheckCircleIcon color="white" />{" "}
                <Text fontSize=".75em" color="white" fontWeight={600}>
                  Copied share link to clipboard.
                </Text>
              </Flex>
            </Flex>
          )}
        </Fade>
        <IconButton
          onClick={() => {
            onCopy();
            setCopiedShareLinkToClipboard(true);

            window.setTimeout(() => setCopiedShareLinkToClipboard(false), 3000);
          }}
          icon={<CopyIcon color="#555" />}
          aria-label="Copy Share Link"
        />
      </Flex>

      <Flex
        position="relative"
        minHeight="480px"
        padding={5}
        paddingBottom={14}
        paddingTop={14}
        alignItems="center"
        justifyContent="center"
        backgroundColor="#333"
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          height="100%"
          width="100%"
          zIndex={0}
          filter="blur(4px)"
          ref={particlesWrapperRef}
          className="synapses"
        >
          {effectLayer}
        </Box>
        <Box zIndex={100}>
          <animated.div
            ref={ref}
            style={{
              ...style,
              ...{ position: "relative" },
            }}
          >
            <Fade in={isUiDataReady}>
              <ChatbotUi
                id={chatbotId}
                name={currentChatbot.name}
                turns={currentChatbotTurns}
                sources={allSources}
                onChatResponse={onChatResponse}
                appearance={{
                  headerBgColor,
                  bgColor,
                }}
                headerRef={dragHandleRef}
                extraHeaderStyles={{
                  cursor: "move",
                }}
              />
            </Fade>
          </animated.div>
        </Box>
      </Flex>
      <Slide
        direction="right"
        in={isSettingsOpen}
        style={{
          zIndex: 400,
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <Flex
          backgroundColor="#fff"
          height="100%"
          width="33%"
          position="absolute"
          right={0}
          flexDirection="column"
          borderLeft="1px solid #dedede"
        >
          <Flex
            borderBottomWidth="1px"
            borderColor="#cdcdcd"
            padding={4}
            alignItems="center"
          >
            <Heading flexGrow={1} size="m">
              Chatbot Settings
            </Heading>
            <IconButton
              icon={<CloseIcon boxSize={2} />}
              aria-label="Close Chatbot Settings"
              size="xs"
              onClick={() => closeSettings()}
            />
          </Flex>
          <Box flexGrow={1} overflow="scroll">
            <Accordion allowToggle defaultIndex={0}>
              <AccordionItem borderTopWidth={0}>
                <AccordionButton
                  borderBottom="1px solid #cdcdcd"
                  background="gray.100"
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize="0.8em"
                    fontWeight={600}
                  >
                    Data
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel padding={0}>
                  <Box padding={4} flexGrow={1} overflow="scroll">
                    <form>
                      <FormControl>
                        <FormLabel fontSize="xs">Name</FormLabel>
                        <Input
                          type="text"
                          isDisabled={true}
                          value={currentChatbot.name}
                        />
                      </FormControl>
                      <FormControl marginTop={4}>
                        <FormLabel fontSize="xs">Prompt Name</FormLabel>
                        <Input
                          type="text"
                          isDisabled={true}
                          value="sample-prompt-1"
                        />
                      </FormControl>

                      {isPersonasEnabled ? (
                        <FormControl marginTop={4}>
                          <FormLabel>Choose a persona</FormLabel>
                          <Select
                            onChange={(e) => setChatbotPersona(e.target.value)}
                            value={chatbotPersona}
                          >
                            <option key="Aristotle" value="Aristotle">
                              Aristotle
                            </option>
                            <option key="Mickey" value="Mickey Mouse">
                              Mickey Mouse
                            </option>
                            <option key="Mario" value="Super Mario">
                              Super Mario
                            </option>
                            <option key="toddler" value="a toddler">
                              a toddler
                            </option>
                          </Select>
                        </FormControl>
                      ) : null}

                      <FormControl marginTop={4}>
                        <Flex alignItems="center">
                          <FormLabel fontSize="xs" flexGrow={1} margin={0}>
                            Add Sources
                          </FormLabel>
                          <IconButton
                            aria-label="Add included link"
                            icon={<AddIcon boxSize={2} />}
                            variant="outline"
                            onClick={addLink}
                            size="xs"
                          />
                        </Flex>
                        <VStack spacing={2} align="stretch" marginTop={2}>
                          {links.map((link, index) => (
                            <HStack key={index} align="center">
                              <Input
                                placeholder="https://www.example.com/"
                                value={link}
                                onChange={(e) => {
                                  setLinks(
                                    links.map((item, i) =>
                                      i === index ? e.target.value : item
                                    )
                                  );

                                  if (shouldPromptAddSource) {
                                    setShouldPromptAddSource(false);
                                  }
                                }}
                                backgroundColor={
                                  index === 0 && shouldPromptAddSource
                                    ? "yellow.100"
                                    : undefined
                                }
                              />
                              <IconButton
                                aria-label="Delete link"
                                icon={<DeleteIcon />}
                                colorScheme="red"
                                variant="ghost"
                                onClick={() => deleteLink(index)}
                                size="sm"
                              />
                            </HStack>
                          ))}
                        </VStack>
                      </FormControl>
                      <FormControl marginTop={4}>
                        <FormLabel fontSize="xs" flexGrow={1} marginTop={4}>
                          Current Sources
                        </FormLabel>

                        <UnorderedList listStyleType="none" margin={0}>
                          {allSources?.map((source, index) => (
                            <ListItem key={index} marginBottom={2}>
                              <HStack key={index} align="center">
                                <Input
                                  title={source}
                                  placeholder="https://www.example.com/"
                                  value={source}
                                  onChange={(e) =>
                                    setLinks(
                                      links.map((item, i) =>
                                        i === index ? e.target.value : item
                                      )
                                    )
                                  }
                                  isDisabled={true}
                                />
                                <IconButton
                                  aria-label="Delete link"
                                  icon={<DeleteIcon />}
                                  colorScheme="red"
                                  variant="ghost"
                                  onClick={() => {
                                    /*noop*/
                                  }}
                                  size="sm"
                                />
                              </HStack>
                            </ListItem>
                          ))}
                        </UnorderedList>
                      </FormControl>
                    </form>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton
                  borderBottom="1px solid #cdcdcd"
                  background="gray.100"
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontSize="0.8em"
                    fontWeight={600}
                  >
                    Appearance
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel padding={0}>
                  <Box padding={4} flexGrow={1} overflow="scroll">
                    <form>
                      <FormControl>
                        <FormLabel fontSize="xs">Header Color</FormLabel>
                        <Select
                          onChange={(e) =>
                            setHeaderBgColor(
                              e.target.value as "gray" | "blue" | "purple"
                            )
                          }
                          defaultValue="blue"
                        >
                          <option key="blue" value="blue">
                            blue
                          </option>
                          <option key="gray" value="gray">
                            gray
                          </option>
                          <option key="purple" value="purple">
                            purple
                          </option>
                        </Select>
                      </FormControl>
                      <FormControl marginTop={4}>
                        <FormLabel fontSize="xs">Background Color</FormLabel>
                        <Select
                          onChange={(e) =>
                            setBgColor(e.target.value as "gray" | "white")
                          }
                          defaultValue="white"
                        >
                          <option key="white" value="white">
                            white
                          </option>
                          <option key="gray" value="gray">
                            gray
                          </option>
                        </Select>
                      </FormControl>
                    </form>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
          <Box padding={4} borderTopWidth="1px" width="100%">
            {isSaving ? (
              <Flex justifyContent="center">
                <Spinner margin="5px" size="md" color="gray.500" />
              </Flex>
            ) : (
              <Button onClick={onSave} width="100%" colorScheme="blue">
                Save changes
              </Button>
            )}
          </Box>
        </Flex>
      </Slide>
    </Box>
  );
};

// Simulated ingestion progress bar
const TimedProgress = ({ numUrls }: { numUrls: number }) => {
  const [ingestedCount, setIngestedCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () => setIngestedCount((prev) => prev + 1),
      5500
    );
  }, []);

  return (
    <Flex
      gap={1}
      alignItems="flex-end"
      direction={"column"}
      justifyContent="center"
    >
      <Progress
        isAnimated={true}
        hasStripe={true}
        value={(ingestedCount / numUrls) * 100}
        width="150px"
        height="5px"
        colorScheme="green"
      />
      <Text color="white" fontSize=".75rem" fontWeight={600}>
        Indexing sources
      </Text>
    </Flex>
  );
};

type AppearanceConfig = {
  headerBgColor: "gray" | "blue" | "purple";
  bgColor: "white" | "gray";
};

const HEADER_COLOR_PARAM_TO_CHAKRA_VALUE: Record<
  "gray" | "blue" | "purple",
  string
> = {
  gray: "gray.500",
  blue: "blue.500",
  purple: "purple.500",
};

const BODY_COLOR_PARAM_TO_CHAKRA_VALUE: Record<"white" | "gray", string> = {
  gray: "gray.200",
  white: "white",
};

export const ChatbotUi = ({
  id,
  name,
  turns,
  sources,
  onChatResponse,
  isFullScreen,
  appearance = {
    headerBgColor: "blue",
    bgColor: "white",
  },
  headerRef,
  extraHeaderStyles = {},
}: {
  id: string;
  name: string;
  turns: Array<ChatTurn>;
  sources?: Array<string>;
  onChatResponse?: () => void;
  isFullScreen?: boolean;
  appearance?: AppearanceConfig;
  headerRef?: Ref<HTMLDivElement>;
  extraHeaderStyles?: Record<string, any>;
}) => {
  const { startChat, sendMessage } = useChat();
  const [updatedTurns, setUpdatedTurns] = useState<Array<ChatTurn>>(turns);
  const [pendingInput, setPendingInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const endMarkerRef = useRef<HTMLDivElement | null>(null);

  const onSend = async () => {
    setIsLoading(true);
    setPendingInput("");

    setUpdatedTurns((prev) => [
      ...prev,
      {
        query: pendingInput,
      },
    ]);

    if (updatedTurns.length) {
      const result = await sendMessage(id, pendingInput);
      onChatResponse?.();

      setIsLoading(false);
      if (!result.data) {
        return;
      }
      setUpdatedTurns((prev) => [
        ...prev.slice(0, -1),
        {
          answer: result.data.answer,
          query: pendingInput,

          id: result.data.turn_id,
          chat_id: result.data.chat_id,
        },
      ]);
    } else {
      const result = await startChat(id, pendingInput);
      onChatResponse?.();
      setIsLoading(false);
      if (!result.data) {
        return;
      }
      setUpdatedTurns((prev) => [
        ...prev.slice(0, -1),
        {
          answer: result.data.answer,
          query: pendingInput,

          id: result.data.turn_id,
          chat_id: result.data.chat_id,
        },
      ]);
    }
  };

  useEffect(() => {
    if (endMarkerRef.current) {
      endMarkerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [updatedTurns]);

  useEffect(() => {
    setUpdatedTurns(turns);
  }, [turns]);

  return (
    <Flex
      width={isFullScreen ? "100%" : "600px"}
      height={isFullScreen ? "100%" : "600px"}
      backgroundColor={BODY_COLOR_PARAM_TO_CHAKRA_VALUE[appearance.bgColor]}
      borderRadius={4}
      flexDirection="column"
      overflow="hidden"
      borderWidth="1px"
      borderColor="gray.300"
    >
      <Box
        padding={4}
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor="gray.400"
        background={
          HEADER_COLOR_PARAM_TO_CHAKRA_VALUE[appearance.headerBgColor]
        }
        ref={headerRef}
        style={extraHeaderStyles}
      >
        <Heading size="xs" color="white">
          {name}
        </Heading>
      </Box>
      <Flex position="relative" height="100%" width="100%">
        <Flex
          width="100%"
          height="100%"
          flexGrow={1}
          padding={4}
          flexDirection="column"
          gap={4}
          position="absolute"
          top={0}
          left={0}
          overflow="scroll"
        >
          {updatedTurns.map((turn, index) => (
            <Flex
              flexDirection="column"
              gap={4}
              key={turn.id ?? "preemptive-turn-id"}
            >
              <Flex justifyContent="flex-end">
                <Box
                  backgroundColor="green.500"
                  fontSize=".9rem"
                  color="white"
                  borderRadius={4}
                  paddingLeft={2}
                  paddingRight={2}
                  paddingTop={1}
                  paddingBottom={1}
                  maxWidth="85%"
                >
                  {turn.query}
                </Box>
              </Flex>
              {turn.answer && (
                <Flex>
                  <Box
                    backgroundColor="gray.500"
                    fontSize=".9rem"
                    color="white"
                    borderRadius={4}
                    paddingLeft={2}
                    paddingRight={2}
                    paddingTop={1}
                    paddingBottom={1}
                    maxWidth="85%"
                  >
                    {turn.answer}
                  </Box>
                </Flex>
              )}
            </Flex>
          ))}
          {isLoading && (
            <Box>
              <Box className="chat-loader"></Box>
            </Box>
          )}
          {/* An empty div that acts as a marker for the end of the list. We use this to facilitate scrolling to end of the list */}
          <Box ref={endMarkerRef} />
        </Flex>
        {sources && sources.length === 0 && (
          <Flex
            background="white"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
          >
            <Text color="gray.700" fontWeight={600}>
              No data sources found
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex
        padding={2}
        borderTopWidth="1px"
        borderTopStyle="solid"
        borderTopColor="gray.300"
        gap={2}
      >
        <Input
          placeholder="Send a message..."
          background="white"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="gray.300"
          onChange={(e) => setPendingInput(e.target.value)}
          value={pendingInput}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSend();
              e.preventDefault();
            }
          }}
          isDisabled={isLoading || !sources || sources.length === 0}
        />
        <Button
          onClick={onSend}
          isDisabled={
            !pendingInput || isLoading || !sources || sources.length === 0
          }
        >
          Send
        </Button>
      </Flex>
    </Flex>
  );
};

const LightBulbIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height="16x"
    width="16px"
    version="1.1"
    id="Capa_1"
    viewBox="0 0 279.682 279.682"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill="#000002"
        d="M143.25,55.486c-41.06,0-74.465,33.405-74.465,74.465c0,16.824,5.511,32.711,15.938,45.939   c1.998,2.536,4.15,5.033,6.23,7.448c6.212,7.208,12.078,14.017,14.166,21.675c0.045,0.165,0.438,1.773,0.38,7.247l-0.01,0.791   c-0.063,4.444-0.147,10.528,4.352,15.091c3.081,3.125,7.399,4.645,13.204,4.645h40.272c6.268,0,10.774-1.534,13.776-4.689   c4.061-4.267,3.789-9.779,3.608-13.427c-0.032-0.645-0.066-1.296-0.074-1.944c-0.065-5.48,0.345-7.025,0.362-7.09   c2.121-7.657,8.993-15.732,15.057-22.855c2.023-2.377,3.934-4.622,5.714-6.879c10.431-13.23,15.944-29.12,15.944-45.951   C217.705,88.892,184.305,55.486,143.25,55.486z M189.982,166.614c-1.607,2.036-3.429,4.178-5.358,6.445   c-7.07,8.307-15.084,17.722-18.089,28.572c-0.429,1.546-0.988,4.395-0.905,11.273c0.01,0.835,0.049,1.675,0.091,2.507   c0.032,0.657,0.075,1.523,0.071,2.209c-0.528,0.086-1.325,0.166-2.475,0.166h-40.272c-1.276,0-2.022-0.135-2.405-0.237   c-0.198-0.977-0.17-3.007-0.152-4.287l0.012-0.844c0.072-6.919-0.483-9.789-0.907-11.348c-2.98-10.936-10.575-19.749-17.275-27.524   c-2.066-2.398-4.019-4.664-5.813-6.942c-8.32-10.557-12.718-23.232-12.718-36.654c0-32.789,26.676-59.465,59.465-59.465   c32.783,0,59.455,26.676,59.455,59.465C202.705,143.379,198.306,156.058,189.982,166.614z"
      />
      <path
        fill="#000002"
        d="M161.766,239.564h-37.041c-7.995,0-14.5,6.505-14.5,14.5v11.117c0,7.995,6.505,14.5,14.5,14.5   h37.041c7.995,0,14.5-6.505,14.5-14.5v-11.117C176.266,246.069,169.761,239.564,161.766,239.564z M161.266,264.682h-36.041v-10.117   h36.041V264.682z"
      />
      <path
        fill="#000002"
        d="M143.245,45.779c4.143,0,7.5-3.357,7.5-7.5V7.5c0-4.143-3.357-7.5-7.5-7.5   c-4.143,0-7.5,3.357-7.5,7.5v30.779C135.745,42.422,139.103,45.779,143.245,45.779z"
      />
      <path
        fill="#000002"
        d="M241.917,34.598c-2.858-2.995-7.606-3.106-10.604-0.248l-22.77,21.73   c-2.997,2.859-3.107,7.607-0.248,10.604c1.474,1.544,3.448,2.322,5.427,2.322c1.86,0,3.725-0.688,5.177-2.074l22.77-21.731   C244.666,42.342,244.776,37.594,241.917,34.598z"
      />
      <path
        fill="#000002"
        d="M264.273,109.599c-0.004,0-0.008,0-0.012,0l-29.311,0.047c-4.143,0.007-7.495,3.37-7.488,7.512   c0.007,4.139,3.363,7.488,7.5,7.488c0.004,0,0.008,0,0.012,0l29.311-0.047c4.143-0.007,7.495-3.37,7.488-7.512   C271.767,112.948,268.41,109.599,264.273,109.599z"
      />
      <path
        fill="#000002"
        d="M74.386,64.684c2.859-2.996,2.749-7.743-0.248-10.604l-22.77-21.73   c-2.994-2.858-7.742-2.749-10.604,0.248c-2.859,2.996-2.749,7.743,0.248,10.604l22.77,21.731c1.452,1.386,3.315,2.074,5.177,2.074   C70.937,67.006,72.912,66.228,74.386,64.684z"
      />
      <path
        fill="#000002"
        d="M44.729,109.646l-29.31-0.047c-0.004,0-0.008,0-0.012,0c-4.137,0-7.493,3.351-7.5,7.488   c-0.007,4.142,3.346,7.505,7.488,7.512l29.31,0.047c0.004,0,0.008,0,0.012,0c4.137,0,7.493-3.351,7.5-7.488   C52.225,113.016,48.872,109.652,44.729,109.646z"
      />
    </g>
  </svg>
);
