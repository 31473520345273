import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCozLPwhQ-0HjefkfFlxJzw7JGH947udsM",
  authDomain: "vectara-base-f11c9.firebaseapp.com",
  projectId: "vectara-base-f11c9",
  storageBucket: "vectara-base-f11c9.appspot.com",
  messagingSenderId: "45392233809",
  appId: "1:45392233809:web:89e963d1f86533d3173f70"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);