import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

interface EffectsContextType {
  didLoadParticles: boolean;
  particleConfig: Record<string, any>;
}

const PARTICLE_CONFIG = {
  fpsLimit: 120,
  particles: {
    color: {
      value: "#999",
    },
    links: {
      color: "#999",
      distance: 150,
      enable: true,
      opacity: 1,
      width: 0.8,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: {
        default: "bounce",
      },
      random: false,
      speed: 1,
      straight: false,
    },
    number: {
      density: {
        enable: false,
        height: 20,
        width: 200,
      },
      value: 80,
    },
    opacity: {
      value: 1,
    },
    shape: {
      type: "circle",
    },
    size: {
      value: { min: 5, max: 9 },
    },
  },
  detectRetina: true,
};

const EffectsContext = createContext<EffectsContextType | undefined>(undefined);

export const useEffectsContext = (): EffectsContextType => {
  const context = useContext(EffectsContext);

  if (!context) {
    throw new Error("Invalid configuraiton");
  }
  return context;
};

interface EffectsProviderProps {
  children: ReactNode;
}

export const EffectsProvider: React.FC<EffectsProviderProps> = ({
  children,
}) => {
  const [didLoadParticles, setDidLoadParticles] = useState<boolean>(false);

  useEffect(() => {
    if (didLoadParticles) return;
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setDidLoadParticles(true);
    });
  }, []);

  const value: EffectsContextType = {
    didLoadParticles,
    particleConfig: PARTICLE_CONFIG,
  };

  return (
    <EffectsContext.Provider value={value}>{children}</EffectsContext.Provider>
  );
};
