import { useAuth } from "../context/AuthContext";

const API_URL_PREFIX = process.env.REACT_APP_API_URL;

export const useChat = () => {
  const { user } = useAuth();

  const startChat = async (id: string, message: string): Promise<any> => {
    if (!user) {
      throw "Unauthenticated";
    }

    const authToken = await user.getIdToken();
    const response = await fetch(`${API_URL_PREFIX}/chat/chatbot/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: message,
        chatbot_persona:
          window.localStorage.getItem(`chatbot-persona:${id}`) ?? undefined,
      }),
    });

    const json = await response.json();

    return json;
  };

  const sendMessage = async (id: string, message: string): Promise<any> => {
    if (!user) {
      throw "Unauthenticated";
    }

    const authToken = await user.getIdToken();
    const response = await fetch(`${API_URL_PREFIX}/chat/chatbot/${id}/turn`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: message,
        chatbot_persona:
          window.localStorage.getItem(`chatbot-persona:${id}`) ?? undefined,
      }),
    });

    const json = await response.json();

    return json;
  };

  return {
    startChat,
    sendMessage,
  };
};
