import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { useLocation } from "react-router-dom";
import { BreadcrumbData } from "../dashboard/types";

interface NavContextType {
  breadcrumbs: Array<BreadcrumbData>;
  setBreadcrumbs: (breadcrumbs: Array<BreadcrumbData>) => void;
}

const NavContext = createContext<NavContextType | undefined>(undefined);

export const useNavContext = (): NavContextType => {
  const context = useContext(NavContext);

  if (!context) {
    throw new Error("Invalid configuraiton");
  }
  return context;
};

interface NavProviderProps {
  children: ReactNode;
}

export const NavProvider: React.FC<NavProviderProps> = ({ children }) => {
  const { pathname } = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState<Array<BreadcrumbData>>([]);

  useEffect(() => {
    const updatedBreadcrumbs = getStaticBreadcrumbsFromPath(pathname);

    // Update breadcrumbs based on path only if we were able
    // to parse them out.
    if (updatedBreadcrumbs.length) {
      setBreadcrumbs(updatedBreadcrumbs);
    }
  }, [pathname]);

  const value: NavContextType = { breadcrumbs, setBreadcrumbs };

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

const PATHNAME_TO_BREADCRUMB: Record<string, BreadcrumbData> = {
  chatbots: {
    name: "Chatbots",
    link: "/chatbots",
  },
  settings: {
    name: "Settings",
    link: "/settings",
  },
};

const getStaticBreadcrumbsFromPath = (path: string): Array<BreadcrumbData> => {
  const pathParts = path.split("/");
  const breadcrumbs: Array<BreadcrumbData> = [];

  pathParts.forEach((part) => {
    if (!part) return;

    if (PATHNAME_TO_BREADCRUMB[part]) {
      breadcrumbs.push(PATHNAME_TO_BREADCRUMB[part]);
    }
  });

  return breadcrumbs;
};
