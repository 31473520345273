import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useAuth } from "./context/AuthContext";
import GoogleSignInButton from "./components/GoogleSignInButton";
import { useNavigate } from "react-router-dom";

export const Homepage = () => {
  const { user } = useAuth();
  let navigate = useNavigate();

  return (
    <>
      <Box className="App-header">
        <Heading color="#333" size="4xl" marginBottom=".5rem">
          ChatUltra
        </Heading>
        <Heading color="#333" size="m" marginBottom="2.5rem">
          Superior chatbots. Powered by Vectara.
        </Heading>
        {user ? (
          <Button onClick={() => navigate("/chatbots")}>
            Go to your dashboard
          </Button>
        ) : (
          <GoogleSignInButton onSignIn={() => navigate("/chatbots")} />
        )}
      </Box>
    </>
  );
};
