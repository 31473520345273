import { Flex, Spinner } from "@chakra-ui/react";
import { useChatbotContext } from "../context/ChatbotContext";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ChatbotUi } from "../dashboard/Chatbot";

const ALLOWED_HEADER_COLORS = ["blue", "gray", "purple"];
const ALLOWED_BG_COLORS = ["gray", "white"];

export const Share = () => {
  const { currentChatbot, currentChatbotTurns, loadChatbot, listSources } =
    useChatbotContext();
  const [sources, setSources] = useState<Array<string> | undefined>(undefined);

  const [searchParams] = useSearchParams();
  const headerColor = searchParams.get("headerColor") ?? "";
  const bgColor = searchParams.get("bgColor") ?? "";

  const normalizedHeaderColor = ALLOWED_HEADER_COLORS.includes(headerColor)
    ? headerColor
    : "blue";
  const normalizedBgColor = ALLOWED_BG_COLORS.includes(bgColor)
    ? bgColor
    : "white";

  const params = useParams();
  const id = params.chatbotId;

  useEffect(() => {
    if (!id) {
      return;
    }

    const fetchChatbotData = async () => {
      await loadChatbot(Number(id));

      const sources = await listSources(id);
      if (!sources) {
        setSources([]);
      }
      setSources(sources);
    };

    fetchChatbotData();
  }, [id]);

  const canRenderChatbot = id && currentChatbot;
  return (
    <Flex
      height="100vh"
      width="100vw"
      alignItems="center"
      justifyContent="center"
      padding={0}
    >
      {canRenderChatbot ? (
        <ChatbotUi
          id={id}
          name={currentChatbot.name}
          turns={currentChatbotTurns}
          isFullScreen={true}
          sources={sources}
          appearance={{
            headerBgColor: normalizedHeaderColor as "blue" | "gray" | "purple",
            bgColor: normalizedBgColor as "gray" | "white",
          }}
        />
      ) : (
        <Flex>
          <Spinner size="xl" />
        </Flex>
      )}
    </Flex>
  );
};
