export const getToken = (): string | null => {
    return localStorage.getItem('token');
}

export const setToken = (token: string): void => {
    localStorage.setItem('token', token);
}

export const setUserData = (data: any): void => {
    localStorage.setItem('userData', JSON.stringify(data));
}

export const getUserData = (): { name: string, email: string, imgUrl: string, user_id: string } | null => {
    const data = localStorage.getItem('userData');
    return data ? JSON.parse(data) : null;
}

export const clearAll = (): void => {
    localStorage.clear();
}