import { useAuth } from "../context/AuthContext";
import { ChatbotData } from "./types";

const API_URL_PREFIX = process.env.REACT_APP_API_URL;

export const useChatbot = () => {
  const { user } = useAuth();

  const getChatbot = async (id: number): Promise<ChatbotData> => {
    if (!user) {
      throw "Unauthenticated";
    }

    const authToken = await user.getIdToken();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chat/chatbot/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const json = await response.json();

    return json?.data ?? {};
  };

  // TODO: move to context
  const createChatbot = async (
    name: string,
    prompt: string,
    persona?: string
  ) => {
    if (!user) {
      throw "Unauthenticated";
    }

    const authToken = await user.getIdToken();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chat/chatbot`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          prompt_id: Number(prompt),
        }),
      }
    );

    const json = await response.json();

    // mock storing chatbot persona
    if (persona) {
      window.localStorage.setItem(`chatbot-persona:${json.id}`, persona);
    }

    return json;
  };

  const updateChatbot = () => {};

  const deleteChatbot = () => {};

  const addSources = async (chatbotId: string, urls: Array<string>) => {
    if (!user) {
      throw "Unauthenticated";
    }

    const authToken = await user.getIdToken();
    const response = await fetch(
      `${API_URL_PREFIX}/ingest/upload/${chatbotId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          urls: urls,
        }),
      }
    );

    const json = await response.json();

    return json;
  };

  const getSources = async (chatbotId: string, urls: Array<string>) => {};

  return {
    getChatbot,
    updateChatbot,
    deleteChatbot,
    createChatbot,
    addSources,
    getSources,
  };
};
