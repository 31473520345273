import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Homepage } from "./Homepage";
import { Dashboard } from "./dashboard/Dashboard";
import { Chatbots } from "./dashboard/Chatbots";
import { Settings } from "./dashboard/Settings";
import { Chatbot } from "./dashboard/Chatbot";
import CreateChatbot from "./dashboard/CreateChatbot";
import { EffectsProvider } from "./context/EffectsContext";
import { ChatbotProvider } from "./context/ChatbotContext";
import { Share } from "./share/Share";

function App() {
  return (
    <ChakraProvider>
      <AuthProvider>
        <EffectsProvider>
          <BrowserRouter>
            <ChatbotProvider>
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/share/:chatbotId" element={<Share />} />
                <Route element={<Dashboard />}>
                  <Route path="/chatbots" element={<Chatbots />} />
                  <Route path="/create-chatbot" element={<CreateChatbot />} />
                  <Route path="/chatbot/:chatbotId" element={<Chatbot />} />
                  <Route path="/settings" element={<Settings />} />
                </Route>
              </Routes>
            </ChatbotProvider>
          </BrowserRouter>
        </EffectsProvider>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
