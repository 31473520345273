import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { ChatbotData, ChatTurn } from "../dashboard/types";
import { useAuth } from "./AuthContext";
import { useParams } from "react-router-dom";

interface ChatbotContextType {
  currentChatbot: Pick<ChatbotData, "id" | "name"> | null;
  currentChatbotTurns: Array<ChatTurn>;
  loadChatbot: (id: number) => Promise<void>;
  createChatbot: (name: string, prompt: string) => void;
  addSources: (
    chatbotId: string,
    urls: Array<string>,
    onStartIngest?: (urlsToIngest: Array<string>) => void,
    onEndIngest?: () => void
  ) => void;
  listSources: (chatbotId: string) => Promise<Array<string>>;
}

const API_URL_PREFIX = process.env.REACT_APP_API_URL;

const ChatbotContext = createContext<ChatbotContextType | undefined>(undefined);

export const useChatbotContext = (): ChatbotContextType => {
  const context = useContext(ChatbotContext);

  if (!context) {
    throw new Error("Invalid configuration");
  }
  return context;
};

interface ChatbotProviderProps {
  children: ReactNode;
}

export const ChatbotProvider: React.FC<ChatbotProviderProps> = ({
  children,
}) => {
  const params = useParams();
  const { user } = useAuth();
  const [currentChatbot, setCurrentChatbot] = useState<Pick<
    ChatbotData,
    "id" | "name" | "persona"
  > | null>(null);
  const [currentChatbotTurns, setCurrentChatbotTurns] = useState<
    Array<ChatTurn>
  >([]);

  useEffect(() => {
    setCurrentChatbot(null);
  }, [params.chatbotId]);

  const loadChatbot = async (id: number): Promise<void> => {
    if (!user) {
      throw "Unauthenticated";
    }

    const authToken = await user.getIdToken();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chat/chatbot/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const json = await response.json();

    // Simulate persona by fetching from localstorage
    const persona =
      window.localStorage.getItem(`chatbot-persona:${id}`) ?? undefined;

    if (json) {
      setCurrentChatbot({
        name: json.data.name,
        id: json.data.id,
        persona,
      });

      setCurrentChatbotTurns(json.data.turns);
    }
  };

  const createChatbot = async (name: string, prompt: string) => {
    if (!user) {
      throw "Unauthenticated";
    }

    const authToken = await user.getIdToken();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chat/chatbot`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          prompt_id: Number(prompt),
        }),
      }
    );

    const json = await response.json();

    return json.data;
  };

  const addSources = async (
    chatbotId: string,
    urls: Array<string>,
    onStartIngest?: (urlsToIngest: Array<string>) => void,
    onEndIngest?: () => void
  ) => {
    if (!user) {
      throw "Unauthenticated";
    }

    const authToken = await user.getIdToken();

    let response = await fetch(`${API_URL_PREFIX}/sources/list_sources`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        base_urls: urls,
      }),
    });

    const associatedUrls = (
      (await response.json()).associated_urls ?? []
    ).slice(0, 2);

    const urlsToIngest = Array.from(new Set([...urls, ...associatedUrls]));

    onStartIngest?.(urlsToIngest);

    response = await fetch(`${API_URL_PREFIX}/ingest/upload/${chatbotId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        urls: urlsToIngest,
      }),
    });

    onEndIngest?.();

    const json = await response.json();

    return json;
  };

  const listSources = async (chatbotId: string): Promise<Array<string>> => {
    if (!user) {
      throw "Unauthenticated";
    }

    const authToken = await user.getIdToken();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/sources/get_chatbot_sources/${chatbotId}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const json = await response.json();
    if (json) {
      return json.sources;
    }
    return [];
  };

  const value: ChatbotContextType = {
    currentChatbot,
    currentChatbotTurns,
    loadChatbot,
    createChatbot,
    addSources,
    listSources,
  };

  return (
    <ChatbotContext.Provider value={value}>{children}</ChatbotContext.Provider>
  );
};
