import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { NavProvider, useNavContext } from "../context/NavContext";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useAuth } from "../context/AuthContext";
import { useEffect } from "react";

export const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, []);

  if (!user) {
    return null;
  }

  return (
    <>
      <NavProvider>
        <Header />
        <Outlet />
      </NavProvider>
    </>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const { breadcrumbs } = useNavContext();
  const { logout } = useAuth();

  const onSignOut = () => {
    logout();
    navigate("/");
  };

  return (
    <Flex
      padding="1rem"
      gap="1rem"
      borderBottom="1px solid #dedede"
      position="relative"
      zIndex={200}
    >
      <Flex flexGrow={1} alignItems="center">
        <Breadcrumb separator={<ChevronRightIcon color="#333" />}>
          {breadcrumbs.map((breadcrumb, index) => (
            <BreadcrumbItem key={`breadcrumb-${index}-${breadcrumb.name}`}>
              {breadcrumb.link ? (
                <BreadcrumbLink as={Link} to={breadcrumb.link}>
                  <Text fontWeight={600}>{breadcrumb.name}</Text>
                </BreadcrumbLink>
              ) : (
                <Text fontWeight={600}>{breadcrumb.name}</Text>
              )}
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      </Flex>
      <Box>
        <Menu>
          <MenuButton as={IconButton} icon={<UserProfileIcon />}>
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem as={Link} to="/chatbots">
              Chatbots
            </MenuItem>
            <MenuItem as={Link} to="/settings">
              Settings
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={onSignOut}>Sign Out</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};

const UserProfileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height="100%"
      width="20px"
      version="1.1"
      id="Capa_1"
      viewBox="0 0 60.671 60.671"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <ellipse
            fill="#010002"
            cx="30.336"
            cy="12.097"
            rx="11.997"
            ry="12.097"
          />
          <path
            fill="#010002"
            d="M35.64,30.079H25.031c-7.021,0-12.714,5.739-12.714,12.821v17.771h36.037V42.9    C48.354,35.818,42.661,30.079,35.64,30.079z"
          />
        </g>
      </g>
    </svg>
  );
};
